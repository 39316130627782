<template>
  <header>
    <div class="header-top">
      <a :href="`tel:${tel}`" target="_blank" rel="noopener noreferrer">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone"
            viewBox="0 0 16 16">
          </svg>
          <strong>{{ formatPhoneNumber(tel) }}</strong>
        </span>
      </a>
      <a :href="`https://api.whatsapp.com/send?phone=${tel}&amp;text=${msg}`" target="_blank" rel="noopener noreferrer">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp"
            viewBox="0 0 16 16">
          </svg>
          <strong>{{ formatPhoneNumber(tel) }}</strong>&nbsp;&nbsp;
        </span>
      </a>
    </div>
    <nav>
      <div :style="{ display: 'flex',
        flexDirection: this.screenWidth <= 800 ? 'column' : 'row'}">

        <router-link :style="{ minWidth: '100vw', maxWidth: '25px', padding: '12px 20px' }" v-on:click="menu"
          to="/">Home</router-link>

        <RouterLink :style="{ minWidth: '100vw', padding: '12px 20px' }" v-on:click="menu" to="/a-empresa/">A
          Empresa</RouterLink>

        <RouterLink :style="{ minWidth: '100vw', padding: '12px 20px' }" to="/servicos/" v-on:click="menu">
          Serviços</RouterLink>

        <RouterLink :style="{ minWidth: '100vw', padding: '12px 20px' }" to="/galeria-de-fotos/" v-on:click="menu"
          aria-current="page" class="m m-active">Galeria
        </RouterLink>

        <RouterLink :style="{ minWidth: '100vw', padding: '12px 20px' }" to="/fale-conosco/" v-on:click="menu">
          Fale Conosco</RouterLink>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: 'header-cps',
  data () {
    return {
      tel: process.env.VUE_APP_WHATSAPP_NUMBER,
      email: process.env.VUE_APP_EMAIL,
      msg: process.env.VUE_APP_MSG,
      escreenWidth: screen.width,
      escreenHeight: screen.height,
      screenWidth: window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    formatPhoneNumber (number) {
      const cleaned = ('' + number).replace(/\D/g, '')

      if (cleaned.length === 13) {
        return `(${cleaned.slice(2, 4)}) ${cleaned.slice(4, 5)} ${cleaned.slice(5, 9)}-${cleaned.slice(9)}`
      } else {
        return number
      }
    },
    handleResize () {
      this.screenWidth = window.innerWidth
    },
    menu () {
      if (this.escreenWidth <= 600) {
        if (document.getElementById('subnav').style.display === 'none') {
          document.getElementById('subnav').style.display = 'block'
        } else {
          document.getElementById('subnav').style.display = 'none'
        }
      }
    }
  }
}
</script>
