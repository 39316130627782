<template async>
  <Header />
  <Banner />
  <article>
    <router-view class="router" />
  </article>
  <Footer />
</template>
<script async>
export default {
  name: 'app-main',
  components: {
    Header: require('@/components/Header.vue').default,
    Banner: require('@/components/Banner.vue').default,
    Footer: require('@/components/Footer.vue').default
  }
}
</script>
