<template>
  <div>
    <a target="_blank" :href="`https://api.whatsapp.com/send?phone=${tel}&amp;text=${msg}`"
      aria-label="Entrar em contato pelo WhatsApp" name="whatsapp" class="whatsapp-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp"
        viewBox="0 0 16 16">
        <path
          d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
      </svg>
    </a>
  </div>
  <br><br><br><br>
  <footer class="footer-section">
    <div
      :style="{ display: 'grid', maxWidth: '80vw', margin: '0 auto', gridTemplateColumns: this.screenWidth <= 600 ? 'repeat(1, calc(80vw / 1))' : 'repeat(3, calc(90vw / 3))', columnGap: '20px' }">
      <div>
        <h3>Sobre Nós</h3>
        <p><span>A JR Guincho 24 Horas</span> <strong style="color:#fff000">oferece serviços de guincho
            e reboque</strong><span>&nbsp;24 horas por dia, 7 dias por semana, garantindo
            atendimento rápido e eficiente em toda a região. Nossa equipe de profissionais altamente
            qualificados está sempre pronta para auxiliar em situações de emergência, proporcionando
            segurança e tranquilidade aos nossos clientes. Confie na experiência e dedicação da JR
            Guincho 24 Horas para resolver todas as suas necessidades de transporte de
            veículos.</span></p>
      </div>
      <div>
        <h3>Serviços</h3>
        <ul class="">
          <li><router-link to="/guincho-para-carros/">Guincho para Carros</router-link></li>
          <li><router-link to="/guincho-para-motos/">Guincho para Motos</router-link></li>
          <li><router-link to="/guincho-para-motonetas/">Guincho para Motonetas</router-link></li>
          <li><router-link to="/guincho-para-maquinas/">Guincho para Máquinas</router-link></li>
          <li><router-link to="/guincho-para-equipamentos/">Guincho para Equipamentos</router-link>
          </li>
          <li><router-link to="/guincho-para-quadriciclos/">Guincho para Quadriciclos</router-link>
          </li>
          <li><router-link to="/guincho-para-triciclos/">Guincho para Triciclos</router-link></li>
          <li><router-link to="/guincho-para-vans/">Guincho para Vans</router-link></li>
          <li><router-link to="/guincho-para-peruas/">Guincho para Peruas</router-link></li>
          <li><router-link to="/guincho-para-bobcats/">Guincho para Bobcats</router-link></li>
          <li><router-link to="/guincho-para-utilitarios/">Guincho para Utilitários</router-link></li>
          <li><router-link to="/guincho-para-barcos/">Guincho para Barcos</router-link></li>
          <li><router-link to="/guincho-para-jet-skis/">Guincho para Jet-skis</router-link></li>
          <li><router-link to="/guincho-para-lanchas/">Guincho para Lanchas</router-link></li>
        </ul>
      </div>
      <div>
        <h3>Informações Do Negócio</h3>
        <div class="contact-info">
          <a target="_blank" :href="`https://api.whatsapp.com/send?phone=${tel}&amp;text=${msg}`" name="whatsapp"
            class="icon-text">
            <i class="fab fa-whatsapp"></i>
            <span>{{ formatPhoneNumber(tel) }}</span>
          </a>
          <a target="_blank" :href="`tel:${tel}`" class="icon-text"><i class="fas fa-phone"></i><span>{{
      formatPhoneNumber(tel) }}</span></a>
          <address><a target="_blank" :href="`mailto:${email}`" class="icon-text"><i
                class="far fa-envelope-open"></i><span>{{ email }}</span></a></address>
        </div>
      </div>
    </div>
    <div class="copyright-area">
      <div class="">
        <ul class="footer-menu">
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/">Contato</router-link></li>
        </ul>
      </div>
      <div class="">
        <p>&copy; {{ year }} - Todos os direitos reservados</p>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data () {
    return {
      year: new Date().getFullYear(),
      tel: process.env.VUE_APP_WHATSAPP_NUMBER,
      email: process.env.VUE_APP_EMAIL,
      msg: process.env.VUE_APP_MSG,
      screenWidth: window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    formatPhoneNumber (number) {
      const cleaned = ('' + number).replace(/\D/g, '')

      if (cleaned.length === 13) {
        return `(${cleaned.slice(2, 4)}) ${cleaned.slice(4, 5)} ${cleaned.slice(5, 9)}-${cleaned.slice(9)}`
      } else {
        return number
      }
    },
    handleResize () {
      this.screenWidth = window.innerWidth
    }
  }
}
</script>
