<template>
  <div class="slides-container">
    <div class="slide" v-for="(slide, index) in slides" :key="index"
      :style="{ display: slide.display,minWidth: '100vw', maxWidth: '100vw', minHeight: '100vh', backgroundSize:'cover','background-position': 'center center','background-repeat': 'no-repeat',backgroundImage: `linear-gradient(90deg, rgba(1, 18, 72, 0.9), rgba(1, 18, 72, 0.3)), url(${slide.Img})` }">
      <div class="t">
        <h1 class="font-hover">Procurando<br> <span style="color:#fff000!important">{{ slide.sublineSecondLine }}?</span>
        </h1>
        <p>
          Atendimento 24 horas em São José dos Campos, Monteiro Lobato e região. Sempre à sua disposição, todos os dias
          da semana, inclusive feriados!
        </p><br><br>
        <div class="banner-contato">
          <a :href="`https://api.whatsapp.com/send?phone=${tel}&amp;text=${msg}`" class="btn b1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp"
              viewBox="0 0 16 16">
              <path
                d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
            </svg> WhatsApp 24 Horas
          </a>
        </div>
      </div>
    </div>
  </div><br><br><br><br>
</template>
<script>
import pesquisas from '@/assets/pesquisas.json'

export default {
  name: 'banner-cps',
  data () {
    return {
      tel: process.env.VUE_APP_WHATSAPP_NUMBER,
      msg: process.env.VUE_APP_MSG,
      slides: [
        {
          sublineSecondLine: '',
          Img: require('@/assets/b01.webp'),
          srcset: `${require('@/assets/b01.webp')} 400w,
                   ${require('@/assets/b01.webp')} 800w,
                   ${require('@/assets/b01.webp')} 1200w`,
          display: 'none',
          isVisible: false
        },
        {
          sublineSecondLine: '',
          Img: require('@/assets/b01.webp'),
          srcset: `${require('@/assets/b01.webp')} 400w,
                   ${require('@/assets/b01.webp')} 800w,
                   ${require('@/assets/b01.webp')} 1200w`,
          display: 'block',
          isVisible: true
        }
      ]
    }
  },
  mounted () {
    this.setRandomSubline()
    this.nextSlide()
  },
  methods: {
    getRandomSubline () {
      return pesquisas[Math.floor(Math.random() * pesquisas.length)]
    },
    setRandomSubline () {
      this.slides.forEach(slide => {
        slide.sublineSecondLine = this.getRandomSubline()
      })
    },
    nextSlide () {
      const currentSlide = this.slides.findIndex(slide => slide.display === 'block')
      const nextSlide = (currentSlide + 1) % this.slides.length
      this.slides[currentSlide].display = 'none'
      this.slides[currentSlide].isVisible = false
      this.slides[nextSlide].display = 'block'
      this.slides[nextSlide].isVisible = true
      setTimeout(this.nextSlide, 7000)
    }
  }
}
</script>
